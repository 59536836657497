<template>
	<div
		class="full-height"
	>
		<div class="full-height flex-1 pa-10 bg-white">
			<table class="table">
				<thead>
					<tr>
						<th>카테고리</th>
						<th>도움말 수</th>
						<th>관리</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'item_' + index"
					>
						<td>{{ item.name }}</td>
						<td>{{ item.totalContent  | makeComma }}</td>
						<td>
							<button
								class="box pa-5-10 mr-10"
								:class="{'bg-ddd': !item.exposure || item.exposure == 0}"
								@click="item.exposure = !item.exposure"
							>숨김</button>
							<button
								class="box pa-5-10 mr-10 "
								:class="{'bg-identify-outline': item.is_delete}"
								@click="$set(item, 'is_delete', !item.is_delete)"
							>삭제</button>
							<button
								class="box mr-10"
								@click="setSort(item, index,'down')"
							><v-icon>mdi mdi-menu-down</v-icon></button>
							<button
								class="box"
								@click="setSort(item, index,'up')"
							><v-icon >mdi mdi-menu-up</v-icon></button>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="mt-10 text-left">
				숨김 처리시 등록된 도움말도 자동 숨김처리 됩니다. <br/><br/>

				삭제 처리시 등록된 도움말은 자동 삭제 처리되며 복원이 불가능합니다.
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: 'FaqCategory'
		,props: ['items']
		,components: { },
		data: function(){
			return {
				program: {
					name: 'FAQ 카테고리 설정'
					,top: true
					,title: true
					,bottom: false

				}
				,item: {}
				,item_hide: {}
				,item_delete: {}
			}
		}
		,computed: {
			item_list: function(){
				let index = 0
				return this.items.filter(function(item){
					item.index = index
					return item
				})
			}
		}
		,methods: {
			setSort: function(item, index, type){
				if(type == 'up'){
					if(index == 0){
						this.$emit('setNotify', { type: 'error', message: '첫번째 항목입니다'})
					}else{
						let tmp = this.items[index-1]
						this.$set(this.items, index-1, item)
						this.$set(this.items, index, tmp)
					}
				}else{
					if(index == this.items.length -1){
						this.$emit('setNotify', { type: 'error', message: '마지막 항목입니다'})
					}else{
						let tmp = this.items[index+1]
						this.$set(this.items, index+1, item)
						this.$set(this.items, index, tmp)
					}
				}
			}
			,setNotify: function({ type, message }){
				this.$emit('setNotify', { type: type, message: message })
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
		}
	}
</script>